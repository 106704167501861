import 'braid-design-system/reset';

import type { Language, Locale } from '@seek/melways-sites';
import { VocabProvider } from '@vocab/react';
import { BraidProvider } from 'braid-design-system';
import seekJobs from 'braid-design-system/themes/seekJobs';
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router';

import type { JobAdPreview } from 'src/types/JobAdPreviewItem';

import { UIMode } from '../types/UIMode';

import ErrorPage from './Error/ErrorPage';
import { JobAdPreviewContainer } from './JobAdPreviewContainer';

interface AppProps {
  jobAdPreview?: JobAdPreview;
  hasError: boolean;
  language: Language;
  locale: Locale;
}

export default ({ jobAdPreview, hasError, language, locale }: AppProps) => {
  const [uiMode, setUiMode] = useState<UIMode>(undefined);

  useEffect(() => {
    /*
     * Setting uiMode in an effect hook to avoid hydration issues as the
     * server won't necessarily know which mode the client-side will be in.
     * We allow the serverRender to optionally force a particular mode through
     * the uiMode passed in CLIENT_CONTEXT.
     */
    const detectedUIMode = window.top !== window.self ? 'iframe' : 'document';
    const clientUIMode = window.__CLIENT_CONTEXT.uiMode ?? detectedUIMode;
    if (UIMode.guard(clientUIMode)) {
      setUiMode(clientUIMode);
    }
  }, []);

  return (
    <VocabProvider language={language} locale={locale}>
      <BraidProvider theme={seekJobs}>
        {hasError === true ? (
          <ErrorPage uiMode={uiMode} />
        ) : (
          <Routes>
            <Route path="/oops" element={<ErrorPage uiMode={uiMode} />} />
            <Route
              path="*"
              element={
                <JobAdPreviewContainer
                  jobAdPreview={jobAdPreview}
                  uiMode={uiMode}
                />
              }
            />
          </Routes>
        )}
      </BraidProvider>
    </VocabProvider>
  );
};
